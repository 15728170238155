.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    /* padding: 32px 0 0; */
}

.main__content {
    width: 100%;
    /* max-width: 1440px; */
    padding: 0 80px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 60px;
    align-items: center;
}

@media (max-width: 1300px) {
    .main__content {
        padding: 0 60px;
    }
}

@media (max-width: 1000px) {
    .main__content {
        padding: 0 16px;
        gap: 40px;
    }

}