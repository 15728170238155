.showcase-line {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 1280px;
    margin-top: -15px;
}

.showcase-line__title {
    color: var(--neutral-selection);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    white-space: pre-line;
    max-width: 100%;

    color: #101828;
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    /* 107.143% */
}

.showcase-line__btn {
    position: absolute;
    right: 0;
    padding: 8px 12px;
    gap: 6px;
    display: flex;

    border-radius: 6px;
    background: #F3F4F7;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
}

.showcase-line__btn span {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.showcase-line__title-and-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.showcase-line__category {
    justify-content: space-between;
    max-width: 1040px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px 12px;
    justify-content: center;
}

.showcase-line__category-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 112px;
    justify-content: center;
}

.showcase-line__category-card__text {
    color: #000;
    text-align: center;
    /* font-family: Inter; */;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

.showcase-line__items {
    display: grid;

    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    row-gap: 60px;
    column-gap: 32px;
    width: 100%;
    align-items: center;
    justify-items: center;
}

.showcase-line__items_cards-in-line-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 60px;
    column-gap: 32px;
}

.showcase-line__items_cards-in-line-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 16px;
    column-gap: 16px;
}


.showcase-line__items_cards-in-line-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    row-gap: 60px;
    column-gap: 32px;
}

.showcase-line__items_cards-in-line-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    row-gap: 60px;
    column-gap: 32px;
}

.showcase-line__btn_pc {
    display: flex;
}

.showcase-line__btn_mobile {
    display: none;
}

.showcase-line__card__icon {
    width: 100%;
    max-width: 80px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
}

.showcase-line .swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: none !important;
}

.showcase-line .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: calc((100% - 30px) / 2) !important;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

@media (max-width: 1000px) {
    .showcase-line__title {
        font-size: 38px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        /* 110.526% */
    }

    .showcase-line__category-card {
        /* width: 54px; */
    }

    .showcase-line__category-card__text {
        color: #000;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        /* 120% */
    }
}

@media (max-width: 980px) {
    .showcase-line__items_cards-in-line-6 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}

@media (max-width: 900px) {
    .showcase-line__items_cards-in-line-6, .showcase-line__items_cards-in-line-5 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (max-width: 800px) {
    .showcase-line__items_cards-in-line-6, .showcase-line__items_cards-in-line-5, .showcase-line__items_cards-in-line-4 {
        grid-template-columns: repeat(3, minmax(0, 1fr));

    }

    .showcase-line__items {
        column-gap: 14px;
        row-gap: 26px;
    }

    .showcase-line {
        gap: 6px;
    }
}

@media (max-width: 600px) {
    .showcase-line__items_cards-in-line-6, .showcase-line__items_cards-in-line-5, .showcase-line__items_cards-in-line-4, .showcase-line__items_cards-in-line-3 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .showcase-line__btn_pc {
        display: none;
    }

    .showcase-line__btn_mobile {
        display: flex;
        position: relative;
        height: 46px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        /* margin-top: -14px; */

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        padding: 0;
    }

    .showcase-line .swiper {
        display: block !important;
        width: calc(100vw - 16px);
        margin-left: -16px;
        padding: 20px 0 30px 16px;
    }

    .showcase-line__items {
        display: none;
    }
}