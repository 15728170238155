.menu-popup__container {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: visibility 0.3s, opacity 0.3s linear;
}


.menu-popup__container_active {
  visibility: visible;
}

.menu-popup {
  background: var(--website_background-background);
  /* border-radius: 20px; */
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;
  overflow: scroll;
  /* height: fit-content; */
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  padding: 14px 16px 20px 16px;
  /* min-height: 200px; */
  /* box-shadow: 0px 0px 10px rgba(2, 2, 2, 0.08); */
  opacity: 0;
  justify-content: space-between;
}

.menu-popup__header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.menu-popup__logo {
  width: 100%;
  max-width: 310px;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 14px auto 0px auto;
  padding-bottom: 40px;
}

.menu-popup__logo-img {
  width: 100%;
  max-width: 70px;
}

.menu-popup_active {
  opacity: 1;
  animation: fadeinmenu 0.5s;
  /*
    animation-iteration-count: 1; */
}

@keyframes fadeinmenu {
  0% {
    transform: translateX(-50px);
    opacity: 0
  }

  100% {
    transform: translateX(0);
    opacity: 1
  }
}

.menu-popup__close {
  /* margin: 0 auto 0 0; */
  /* position: absolute; */
  top: 0px;
  left: 0px;
}

.menu-popup__close-icon {
  width: 18px;
  height: 18px;
}

.menu-popup__close-icon-fill {
  fill: var(--neutral-active);
}

.menu-popup__box-img {
  width: 100%;
  height: 140px;
  flex-shrink: 0;
  border-radius: 12px;
  background: linear-gradient(180deg, #3B0037 2%, #FFA1F9 117.5%);
  overflow: hidden;
  position: relative;
  margin-top: 14px;
}

.menu-popup__img {
  width: 382.732px;
  height: 396px;
  position: absolute;
  top: -66px;
  left: 18px;
}

.menu-popup__selectors {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: max-content;
  margin: 24px 0 0;
  background: #F0F0F0;
  border-radius: 1000px;
  column-gap: 18px;
}

.menu-popup__btn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 1000px;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
}



.menu-popup__btn-text {
  color: var(--neutral-active);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin: 0;
}

.menu-popup__btn_active {
  background-color: var(--secondary-active);

}

.menu-popup__btn_active .menu-popup__btn-text {
  color: var(--website_background-background);
}


.menu-popup__btn-icon {
  width: 20px;
  height: 20px;
}

.menu-popup__btn-icon-fill {
  fill: var(--secondary-active);
}

.menu-popup__btn-icon-stroke {
  stroke: var(--secondary-active);
}

.menu-popup__btn_active .menu-popup__btn-icon-fill {
  fill: var(--website_background-background);
}

.menu-popup__btn_active .menu-popup__btn-icon-stroke {
  stroke: var(--website_background-background);
}

.menu-popup__line {
  width: 100vw;
  height: 1px;
  background: #A196C0;
  opacity: 0.3;
  /* margin-top: -40px; */
  position: absolute;
  top: 140px;
  left: 0;
}


.menu-popup__cards {
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin: 40px 0 0;
  max-height: calc(100vh - 20px - 53px - 24px - 34px);
  /* overflow: auto; */
  padding-right: 10px;
  padding-bottom: 46px;
}


.menu-popup__cards::-webkit-scrollbar {
  width: 3px;
}

.menu-popup__cards::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px var(--main-element-secondary-bg); */
  border: none;
  outline: none;
  background-color: transparent;
}

.menu-popup__cards::-webkit-scrollbar-thumb {
  background-color: var(--secondary-active);
  outline: none;
  border-radius: 100px;
  overflow: visible;
}

.item-comments__items_hide-scroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px var(--main-element-secondary-bg); */
  border: none;
  outline: none;
  background-color: transparent !important;
}


.menu-popup__card {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 24px;
  grid-template-rows: max-content;
  column-gap: 20px;
  padding: 12px 30px;
  border-radius: calc(var(--initial_border_radius-value) * (1.50 * 2));
  box-sizing: border-box;
  background-color: transparent;
  transition: all 0.2s linear;
  cursor: pointer;
}

.menu-popup__card:hover {
  background: var(--neutral-background);
  border-radius: 5px;
  background: #F9F4FF;
}

.menu-popup__card_selected {
  background: rgba(243, 244, 247, 1);
  border-radius: 5px;
  background: rgba(243, 244, 247, 1);
}

.menu-popup__card-info {
  width: 100%;
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-template-rows: max-content;
  column-gap: 20px;
}

.menu-popup__card-icon {
  width: 24px;
  height: 24px;
}

.menu-popup__card-icon-fill {
  fill: var(--neutral-active);
}

.menu-popup__card-icon-stroke {
  stroke: var(--neutral-active);
}

.menu-popup__card-name {
  margin: 0;
  /* color: var(--neutral-active);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%; */
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 122.222% */
}

.menu-popup__card-arrow {
  width: 20px;
  transition: all 0.2s linear;
  height: 20px;
  fill: #000;
}

.menu-popup__card-arrow-fill {
  fill: var(--secondary-active);
  fill: #000;
}

.menu-popup__card_selected .menu-popup__card-name {
  /* color: var(--secondary-active); */
}

.menu-popup__card_selected .menu-popup__card-icon-fill {
  /* fill: var(--secondary-active); */
  fill: rgba(243, 244, 247, 1)
}

.menu-popup__card_selected .menu-popup__card-icon-stroke {
  stroke: var(--secondary-active);
}

.menu-popup__card_selected .menu-popup__card-arrow {
  transform: rotate(90deg);
  fill: rgba(243, 244, 247, 1)
}


.menu-popup__card-subcategories {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 12px 0 0;

}


.menu-popup__card-preloader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.menu-popup__card-subcategory {
  padding: 10px 12px 10px 51px;
  box-sizing: border-box;
  margin: 0;
  color: var(--neutral-active);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  width: fit-content;
  line-height: 130%;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.menu-popup__card-subcategory:last-of-type {
  padding-bottom: 32px;
}


.menu-popup__card-subcategory-arrow {
  width: 16px;
  height: 16px;
}

.menu-popup__socials {
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: end;
  padding-top: 40px;
  position: relative;
}

.menu-popup__socials__separator {
  position: absolute;
  top: 0;
  height: 1px;
  width: 100vw;
  background: #A196C0;
  opacity: 0.3;
}

.menu-popup__right-reserved {
  text-align: center;
  margin-top: 40px;
}

.menu-popup__socials-box {
  padding-bottom: 120px;
}