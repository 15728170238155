.category-block {
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.category-block__category {
    justify-content: space-between;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    grid-template-rows: 300px;
}

.category-block__category-card {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    width: 100%;
    max-width: 308px;
    justify-content: center;
    height: 100%;
    border-radius: 12px;
    border: 1px solid #E4E4E6;
    background: #FFF;
    /* padding: 60px 78px 30px 78px; */
    box-sizing: border-box;
    position: relative;
}

.category-block__card__icon {
    width: 100%;
    max-width: 152px;
}

.category-block__category-card__text {
    color: #101828;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 127.273% */
    white-space: nowrap;
}

.category-block__number {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    /* 133.333% */
    position: absolute;
    top: 20px;
    left: 20px;
}

.category-block__sale {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.category-block__sale__text {
    /* position: absolute; */
    color: #FFF;
    font-size: 54px;
    font-style: normal;
    font-weight: 800;
    line-height: 60px;
    /* 111.111% */
    letter-spacing: 2.16px;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    /* top: 30px; */
}

.category-block__sale__arrow {
    width: 100%;
    object-fit: fill;
}

.category-block__sale__arrow {
    display: flex;
}

.category-block__sale__arrow_mobile {
    display: none;
}

@media (max-width:960px) {
    .category-block__category-card__text {
        font-size: 18px;
    }
}

@media (max-width:800px) {
    .category-block {
        gap: 10px;
    }

    .category-block__sale {
        min-height: 72px;
    }

    .category-block__category {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: repeat(2, minmax(0, 220px));
        column-gap: 10px;
        row-gap: 10px;
    }

    .category-block__category-card {
        max-width: 100%;
        border-radius: 8px;
    }

    .category-block__card__icon {
        max-width: 100px;
    }

    .category-block__sale__text {
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 36px;
        /* 112.5% */
        letter-spacing: 1.28px;
        text-transform: uppercase;
        top: 18px;
    }

    .category-block__sale__arrow {
        display: none;
    }

    .category-block__sale__arrow_mobile {
        display: flex;
        width: 100%;
        object-fit: fill;
    }

    .category-block__number {
        top: 12px;
        left: 12px;
    }

    .category-block__category-card__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        /* 128.571% */
    }
}