.custom-docs {
    width: 100%;
    max-width: 1440px;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 100vh;
}

.custom-docs__preloader-box {
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.custom-docs__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.custom-docs__title{

}
.custom-docs__text {
    white-space: pre-line;
}


@media (max-width: 500px) {
    .custom-docs {
        padding: 0 16px;
    }
}