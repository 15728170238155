.about-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 80px;
}

.about-block__box {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 283px) minmax(0, 836px);
    grid-template-rows: 302px;
    column-gap: 41px;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 1280px;
}

.about-block__line {
    position: absolute;
    top: -80px;
    width: 100vw;
    height: 1px;
    background: #6F7387;
    opacity: 0.3;
}

.about-block__circle {
    position: absolute;
    max-width: 370px;
    max-height: 370px;
    left: -99px;
    z-index: 0;
}

.about-block__img {
    width: 100%;
    height: 100%;
    max-width: 283px;
    max-height: 302px;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
}

.about-block__text {
    color: #101828;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 136.364% */
}

@media (max-width:990px) {
    .about-block__box {
        padding-bottom: 80px;
    }
}

@media (max-width:800px) {
    .about-block {
        margin-top: 40px;
    }

    .about-block__line {
        top: -40px;
    }

    .about-block__box {
        grid-template-columns: 1fr;
        padding-bottom: 0;
        gap: 41px;
        grid-template-rows: max-content max-content;
    }

    .about-block__text {
        margin-top: 26px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 128.571% */
    }

    .about-block__img {
        margin: 0 auto;
        width: 122.378px;
        height: 130.595px;
    }

    .about-block__circle {
        width: 160px;
        height: 160px;
        top: 0;
        left: calc((100% / 2) - 100px);
    }
}