.static-docs{
    width: 100%;
    max-width: 1440px;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 100vh;
}

.static-docs__text{
    white-space: pre-line;
}


@media (max-width: 500px) {
    .static-docs {
        padding: 0 16px;
    }
}