.product-card {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    justify-content: space-between;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 6px 15px 0px rgba(239, 239, 239, 0.80);
    padding: 16px 16px;
}

.product-card__title {
    display: block;
    /* Adjust if necessary for your layout */
    line-height: calc(var(--h3-size)*1.1);
    /* Example line height; adjust based on your design */
    min-height: calc((var(--h3-size)*1.1)*1);
    /* For two lines of text, assuming line-height is 1.1em */
    overflow-wrap: break-word;
    white-space: pre-line;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    /* Ensures that content does not exceed 2 lines, but does not guarantee a minimum */
    color: var(--neutral-active);
    color: #101828;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 112.5% */
}

.product-card__description {
    display: block;
    /* Adjust if necessary for your layout */
    font-size: var(--caption-size);
    font-weight: var(--caption-weight);
    line-height: calc(var(--caption-size)*1.1);
    /* Example line height; adjust based on your design */
    min-height: calc((var(--caption-size)*1.1)*2);
    /* For two lines of text, assuming line-height is 1.1em */
    overflow-wrap: break-word;
    white-space: pre-line;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* 142.857% */
    /* Ensures that content does not exceed 2 lines, but does not guarantee a minimum */
}

/* Update your existing styles for .product-card__title and .product-card__description to include min-height */
.product-card__title {
    width: 100%;
    line-height: 110%;
    /* Adjust based on your design */
    /* Ensure min-height is set to at least twice the line-height to accommodate two lines */
    min-height: calc((var(--h3-size)*1.1)*1);
    /* Adjust the multiplier based on your actual line-height */
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    white-space: pre-line;
}

.product-card__image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
}

.product-card__image_format_10x16 {
    aspect-ratio: 10 / 16;
}

.product-card__image_format_16x10 {
    aspect-ratio: 16 / 10;
}

.product-card__image_format_1x1 {
    aspect-ratio: 1 / 1;
}


.product-card__button {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 8px;
    white-space: nowrap;
    flex-wrap: wrap;


}

.product-card__button_type_separately {}

.product-card__button_type_on_button {
    padding: 14px 16px;
    box-sizing: border-box;
    border-radius: calc(var(--initial_border_radius-value) * (1.50 * 1));
    background-color: var(--secondary-active);
    transition: all 0.2s ease-in-out;
}


.product-card__button_in-cart {
    background-color: #fff !important;
    color: #000 !important;
    border: 1px solid #000 !important;
}



.product-card__button-price {
    color: var(--neutral-active);
    /* 172.727% */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    /* 154.545% */

    color: #101828;
    /* font-family: Inter; */;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    /* 154.545% */
}



.product-card__button-price-discounted {
    text-decoration: line-through;
    color: #646161;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

}

.product-card__button-price-discounted_unvisible {
    opacity: 0;
}

.product-card__price-discount {
    padding: 4px 8px;
    border-radius: calc(var(--initial_border_radius-value) * (1.50 * 2));
    background-color: var(--semantic_minus-active);
    color: var(--website_background-background);
    position: absolute;
    right: 0;
    transform: rotate(-6.726deg);

    border-radius: 6px;
    background: #F9F4FF;
    color: #524575;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */

    background: #000;
    color: #FFF;
}

.product-card__button_type_on_button .product-card__button-price {
    color: var(--website_background-background);
}

.product-card__button_in-cart .product-card__button-price {
    color: var(--secondary-active);
}

.product-card__button_type_on_button .product-card__button-price-discounted {
    color: var(--website_background-background);
}


.product-card__button_in-cart .product-card__button-price-discounted {
    color: var(--secondary-active);
}

.product-card__button-to-cart {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    border-radius: 6px;
    background: #000;
    border: #000 1px solid;
    padding: 8px 12px;
}

.product-card__button-to-cart-text {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 150% */
}

.product-card__button_type_on_button .product-card__button-to-cart-text {
    color: var(--website_background-background);
}

.product-card__button_in-cart .product-card__button-to-cart-text {
    color: #000;
}


.product-card__button-to-cart-icon {
    width: var(--h3-size);
    height: var(--h3-size);
}

.product-card__button-to-cart-icon-fill {
    fill: var(--secondary-active);
}

.product-card__button_type_on_button .product-card__button-to-cart-icon-fill {
    fill: var(--website_background-background);
}

.product-card__button_in-cart .product-card__button-to-cart-icon-fill {
    fill: var(--secondary-active);
}

.product-card__last {
    margin: auto 0 0;
}

.product-card__button-to-cart_not-in-stok {
    opacity: 0.5;
}

@media (max-width: 1000px) {
    .product-card__title {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        margin-top: 2px;
    }

    .product-card__description {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        /* 116.667% */
        margin-top: -6px;
    }

    .product-card__button {
        margin-top: -2px;
    }

    .product-card__button-price {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        /* 155.556% */
    }

    .product-card__button-to-cart-text {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        /* 116.667% */
    }

    .product-card__button-to-cart-icon {
        width: calc(var(--text-size) * 0.7);
        height: calc(var(--text-size) * 0.7);
    }

    .product-card__image {
        height: unset;
        max-width: 81%;
        margin: auto;
    }
}

@media (max-width:600px) {
    .product-card__title {
        text-align: left;
    }

    .product-card__description {
        text-align: left;
    }
}

@media (max-width:480px) {
    .products__items .product-card__button {
        flex-direction: column;
        align-items: flex-start;
    }

    .products__items .product-card__button-to-cart {
        width: 100%;
        justify-content: center;
        box-sizing: border-box;
    }
}

@media (max-width:380px) {

    .product-card__button {
        /* flex-wrap: wrap; */
        flex-direction: column;
        align-items: flex-start;
    }

    .product-card__description {
        -webkit-line-clamp: 1;
        min-height: 16px;
    }

    .product-card__button-to-cart {
        width: 100%;
        justify-content: center;
        box-sizing: border-box;
    }

    .product-card {
        padding: 10px;
    }
}