.promo100 {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    max-width: 1280px;
}

.promo100__swiper-arrow-btn {
    width: 46px;
    height: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: calc(var(--initial_border_radius-value) * (1.5 * 1));
    background-color: var(--neutral-background);
    position: absolute;
    z-index: 2;

    border-radius: 600px;
    background: #FFF;
    box-shadow: 0px 9px 14px 0px rgba(120, 127, 153, 0.14);
}

.promo100__swiper-arrow-btn_left {
    left: 20px;
    top: 50px;
}

.promo100__swiper-arrow-btn_right {
    right: 20px;
    top: 50px;
}

.promo100__swiper-arrow-btn_left .promo100__swiper-arrow-icon {
    transform: rotate(180deg);
}

.promo100__swiper-arrow-icon {
    width: var(--h3-size);
    height: var(--h3-size);
}

.promo100__swiper-arrow-icon-fill {
    fill: var(--neutral-active);
}

.promo100__slides {
    position: relative;
    z-index: 1;
    width: 100%;
    /* height: 380px; */
    overflow: hidden;
    border-radius: calc(var(--initial_border_radius-value) / (2 * 1));
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);

    border-radius: 24px;
    /* background: #C2C2C2; */
    height: 500px;

}

.promo100__slide {
    width: 100% !important;
    max-width: 100% !important;
    height: fit-content !important;
    /* max-width: 90%; */

    background-color: var(--container-bg-color);
    /* margin-right: 16px; */
    position: relative;
    /* margin-left: 30px; */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    /* overflow: hidden;
    border-radius: calc(var(--initial_border_radius-value) / (2 * 1)); */
}

/* 
.promo100__slide:last-of-type {
    margin-right: 0;
} */
.promo100__slide__box {
    display: flex;
    gap: 40px;
    /* margin-top: 40px; */
    position: absolute;
    z-index: 10;
    top: 84px;
    left: 60px;

    flex-direction: column;
}

.promo100__slide__box-btn {
    display: flex;
    gap: 12px;
}

.promo100__slide__catalog-btn {
    border-radius: 8px;
    background: #fff;
    display: flex;
    padding: 16px 40px;
    align-items: flex-start;
    gap: 6px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    /* 162.5% */
}

.promo100__slide__popup-btn {
    border-radius: 8px;
    background: #FFF;
    backdrop-filter: blur(6.5px);
    display: flex;
    padding: 16px 24px;
    align-items: flex-start;
    gap: 6px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    /* 162.5% */
}

.promo100__slide-img {
    overflow: hidden;
    border-radius: calc(var(--initial_border_radius-value) / (2 * 1));
    width: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
    user-select: none;

    height: 460px;
    border-radius: 24px;
}

.swiper-pagination-bullet {
    background-color: var(--neutral-active) !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--neutral-active) !important;
}

.promo100__slide-title {
    color: var(--neutral-active);
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    white-space: pre-line;

    color: #FFF;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 68px;
    /* 106.25% */
    letter-spacing: 2.56px;
    text-transform: uppercase;
    max-width: 300px;
}

.promo100__slide-subtitle {
    color: #FFF;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 123.077% */
    max-width: 470px;
}

.promo100 .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 8px !important;
}

.promo100__slide-text-box {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

@media (max-width: 1000px) {
    .promo100__slides {
        height: 430px;
        border-radius: 12px;
    }

    .promo100__slide-img {
        height: 400px;
        border-radius: 12px;
    }

    .promo100__slide-title {
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: 30px;
        /* 115.385% */
        letter-spacing: 1.04px;
        text-transform: uppercase;
        left: 20px;
        bottom: 22%;
        top: unset;
    }

    .promo100__slide-subtitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 116%;
        /* 116.667% */
    }

    .promo100__slide__box {
        bottom: 20px;
        top: unset;
        left: 20px;
        width: calc(100% - 40px);
    }

    .promo100__slide__box-btn {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: max-content;

        bottom: 70px;
        left: 20px;
        max-width: 400px;
    }

    .promo100__slide__catalog-btn {
        width: 100%;
        border-radius: 8px;
        text-align: center;

        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        padding: 12px 0;
        height: unset;
        align-items: center;
        justify-content: center;
    }

    .promo100__slide__popup-btn {
        width: 100%;
        border-radius: 8px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        /* padding: 12px 43px; */
        padding: 12px 0;
        height: unset;
        align-items: center;
        justify-content: center;
    }

}

@media (max-width: 770px) {
    .promo100__slide__box {
        top: 30px;
        left: 20p;
    }
}

@media (max-width: 700px) {
    .promo100__swiper-arrow-btn_left {
        left: 4px;
    }

    .promo100__swiper-arrow-btn_right {
        right: 4px;
    }

    .promo100__swiper-arrow-icon {
        width: calc(var(--h3-size)*0.7);
        height: calc(var(--h3-size)*0.7);
    }

    .promo100__swiper-arrow-btn {
        width: calc(var(--h3-size)*1.3);
        height: calc(var(--h3-size)*1.3);
    }

    .promo100__slide__box-btn {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 370px) {
    .promo100__slide__box-btn {
        grid-template-columns: 1fr;
        width: 100%
    }

    .promo100__slide-title {
        font-size: 18px;
        line-height: 115%;
    }

    .promo100__slide-subtitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 116%;
        /* 116.667% */
    }
}