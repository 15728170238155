.advantages {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    max-width: 1122px;
}

.advantages__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.advantages__card__text {
    color: #101828;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 122.222% */
}

@media (max-width:900px) {
    .advantages {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 16px;
        row-gap: 26px;
    }

    .advantages__card__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        /* 128.571% */
    }
}