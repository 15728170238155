.map-block {
    border-radius: 24px;
    width: 100%;
    max-width: 1440px;
}

.map-block__text-box {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    color: #000;
    /* font-family: Inter; */;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 122.222% */
}

.map-block__text {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 122.222% */
}

.map-block__text_coordinates {
    color: #6F7387;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 122.222% */
}

@media (max-width:840px) {
    .map-block {
        border-radius: 12px;
    }

    .map-block__text-box {
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .map-block__text {
        color: #000;
        text-align: center;
        /* font-family: Inter; */;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 128.571% */
    }

    .map-block__text span {
        display: none;
    }

    .map-block__text_coordinates {
        color: #6F7387;
        /* font-family: Inter; */;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 128.571% */
    }
}