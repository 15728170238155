.product-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    padding: 32px 0 0;
    min-height: 70vh;
}

.product-page__content {
    width: 100%;
    max-width: 1440px;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.product-page__crumbs {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    overflow: scroll;
}

.product-page__crumb {
    font-size: var(--text-size);
    font-weight: var(--text-weight);
    color: var(--neutral-active);
}


.product-page__not-found {
    width: 100%;
    height: 70vh;
}

.product-page__preloader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70vh;
}

.product-page__name {
    max-width: 580px;
    margin: 16px 0 0;
}

.product-page__item {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 500px;
    grid-template-rows: max-content;
    column-gap: 24px;
    margin: 24px 0 0;
}

.product-page__photos {
    overflow: hidden;
    width: 100%;
    aspect-ratio: 560 / 372;
    /* border: 1px solid var(--neutral-background); */
    border-radius: calc(var(--initial_border_radius-value) / (2 * 1));
}


.photo-slider__cards {
    width: 100%;
    height: 100%;


}

.photo-slider__card {
    /* aspect-ratio: 280 / 400; */
    /* width: fit-content !important; */
    width: fit-content !important;
    max-width: 100% !important;
    height: 100%;
    /* max-width: 90%; */

    background-color: var(--container-bg-color);
    margin-right: 16px;
    position: relative;
    /* margin-left: 30px; */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: calc(var(--initial_border_radius-value) / (2 * 1));

}

.photo-slider__card:first-of-type {
    /* margin-left: 0; */
}


/* .photo-slider__card:last-of-type {
    margin-right: 16px;
} */

.photo-slider__card-img {
    /* width: 100%; */
    height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.photo-slider__controll {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.photo-slider__controll-icon {
    width: 40px;
    height: 40px;
}

.photo-slider__controll-icon-stroke {
    stroke: var(--text-color);
}

.photo-slider__controll-icon_next {
    transform: rotate(180deg);
}



.swiper-pagination-bullet-active {
    background-color: var(--primary-active) !important;
}

.product-page__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

}

.product-page__description {
    white-space: pre-line;
    font-weight: var(--text-weight);
}

.product-page__in-stock {
    padding-top: 16px;
    margin: auto 0 0;
}

.product-page__buy {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 16px 0 0;
}

.product-page__price {
    display: flex;
    position: relative;
    width: 100%;
    padding: 13px 16px;
    box-sizing: border-box;
    border-radius: calc(var(--initial_border_radius-value) / (2 * 1)) calc(var(--initial_border_radius-value) / (2 * 1)) 0 0;
    background-color: var(--neutral-background);
}

.product-page__price-discount {
    padding: 4px 8px;
    border-radius: calc(var(--initial_border_radius-value) * (1.50 * 2));
    background-color: var(--semantic_minus-active);
    color: var(--website_background-background);
    position: absolute;
    right: -4px;
    top: -4px;
    transform: rotate(-6.726deg);
}

.product-page__price-value-discounted {
    text-decoration: line-through;
}

.product-page__price-value {
    color: var(--neutral-active);
}

.product-page__purchase-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    width: 100%;
    padding: 13px 16px;
    box-sizing: border-box;
    border-radius: 0 0 calc(var(--initial_border_radius-value) / (2 * 1)) calc(var(--initial_border_radius-value) / (2 * 1));
    background-color: var(--secondary-active);
}

.product-page__purchase-btn_type_not-in-stock {
    background-color: var(--website_background-background);
}

.product-page__purchase-btn-text {
    color: var(--website_background-background);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-page__purchase-btn_type_not-in-stock .product-page__purchase-btn-text{
    color: var(--neutral-active);
}

.product-info__add-to-cart-box {
    width: 100%;
    margin: auto 0 0;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content;
    column-gap: 1px;
}

.product-info__add-to-cart-box_in-cart .product-page__purchase-btn {
    background-color: var(--primary-background);
    color: var(--website_background-background);
    border-radius: 0 0px 0px calc(var(--initial_border_radius-value) / (2 * 1));
    transition: all 0.2s linear;
}

.product-info__add-to-cart-box_in-cart .product-page__purchase-btn-text {
    color: var(--website_background-background);
}

.product-page__remove-btn {
    background-color: var(--primary-background);
    color: var(--website_background-background);

    border-radius: 0 0 calc(var(--initial_border_radius-value) / (2 * 1)) 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.2s linear;
}

.product-page__remove-icon {
    width: 12px;
    height: 12px;
}

.product-page__remove-icon-fill {
    fill: var(--website_background-background);
}

.product-page__parameters {
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    row-gap: 24px;
    column-gap: 24px;
    max-width: calc(100% - 300px - 24px);
    width: 100%;
    margin: 20px 0 0;
}

.product-page__parameter {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding-bottom: 2px;
    border-bottom: 1px solid var(--primary-background);
}

.product-page__parameter-title {
    color: var(--neutral-active);
    font-weight: var(--h3-weight);
    overflow-wrap: break-word;
    white-space: pre-line;
    overflow: hidden;
}

.product-page__parameter-value {
    text-align: right;
    overflow-wrap: break-word;
    white-space: pre-line;
    overflow: hidden;
}

.product-page__options {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    margin: 24px 0 0;
}

.product-page__option {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
}

.product-page__option-name {
    color: var(--neutral-active);

}

.product-page__option-name-selected {
    font-size: var(--text-size);
    font-weight: var(--text-weight);
}

.product-page__option-values {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
}

.product-page__option-value {
    font-size: var(--text-size);
    font-weight: var(--text-weight);
    border-radius: calc(var(--initial_border_radius-value) / (2 * 2));
    border: 1px solid var(--neutral-active);
    padding: 4px 6px;
    box-sizing: border-box;
    color: var(--neutral-active);
    transition: all 0.2s linear;
    min-width: calc(var(--text-size)*4);
}

.product-page__option-value_selected {
    background-color: var(--primary-active);
    border-color: var(--primary-active);
    color: var(--website_background-background);
}

@media (max-width: 1000px) {

    .product-page {
        padding: 16px 0 0;
    }

    .product-page__content {
        padding: 0 16px;
    }

    .product-page__parameters {
        max-width: 100%;
    }

    .product-page__item {

        grid-template-columns: 1fr;
        row-gap: 24px;
        margin: 16px 0 0;
    }

    .product-page__name {
        font-size: calc((var(--h2-size))*0.5);
    }


    .product-page__in-stock {
        margin: 0;
    }
}


@media (max-width: 620px) {
    .product-page__parameters {
        grid-template-columns: repeat(2, minMax(0, 1fr));
    }
}

@media (max-width: 350px) {
    .product-page__parameters {
        grid-template-columns: 1fr;
    }
}