.header {
    width: 100%;

    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    position: sticky;
    transition: all 0.3s ease-in-out;
    z-index: 11;
    background-color: var(--website_background-background);
}


.header_hide {
    top: -200px;
}

.header_show {
    top: 0;
}

.header__input-icon {
    position: relative;
    z-index: 5;
    margin-left: 12px;
}

.header__input-icon-fill {
    fill: var(--neutral-active);
}

.header__content {
    max-width: 1280px;
    width: 100%;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    column-gap: 32px;
    align-items: center;
    position: relative;
    z-index: 11;
    background: rgba(255, 255, 255, 0.20);
    padding: 16px 80px 16px;
    /* box-sizing: border-box; */
}

.header__logo {
    /* width: 100%; */
}

.header__logo_pc {
    display: flex;
    gap: 10px;
    align-items: flex-end;
}

.header__logo_mobile {
    display: none;
}

.header__logo-img {
    /* width: 100%; */
    width: 62px;
    height: 48px;
    object-fit: scale-down;
    object-position: left;
    margin-bottom: 2px;
}

.header__catalog-with-links {
    display: grid;
    grid-template-columns: max-content 24px minmax(0, 1fr);
    grid-template-rows: max-content;
    align-items: center;
    column-gap: 28px;
    position: relative;
}

.header__catalog {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
}


.header__catalog-btn {
    /* background-color: var(--secondary-active); */
    padding: 14px 16px;
    border-radius: calc(var(--initial_border_radius-value) * (1.50 * 1));
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    gap: 8px;
    position: relative;
}

.header__catalog-btn-text {
    color: var(--neutral-active);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

.header__categories {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
}

.header__category {
    font-size: var(--h4-size);
    font-weight: var(--h4-weight);
    color: var(--neutral-active);
}


.header__cart-box {
    position: relative;
    aspect-ratio: 1/1;
    width: 40px;
}

.header__cart {
    background: #F3F4F7;
    color: var(--website_background-background);
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: 10px; */
    padding: 9px;
    border-radius: 50%;
}

.header__cart-text {
    color: var(--website_background-background);

}

.header__cart-icon {
    width: 16px;
    height: 14px;
}

.header__cart-icon-fill {
    fill: var(--website_background-background);
}

.header__cart-count {
    font-size: var(--caption-size);
    font-weight: var(--caption-weight);

    position: absolute;
    right: -6px;
    top: -6px;
    background-color: #000;
    color: var(--website_background-background);
    border-radius: calc(var(--initial_border_radius-value) * (1.50 * 1));
    min-width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    box-sizing: border-box;

    border-radius: 50%;
}

.header__catalog-btn-icon {
    width: 20px;
    height: 20px;
}

.header__catalog-btn-icon-fill {
    fill: var(--website_background-background);
}

.header__catalog-btn-icon_open-catalog {
    opacity: 0;
}

.header__catalog-btn-icon_close-catalog {
    position: absolute;
    z-index: 3;
    opacity: 0;
    /* background-color: var(--secondary-active); */
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
    right: 16px;
    top: 17px;
}

.header__catalog-btn-icon_close-catalog-open {
    opacity: 1;
}

.header__menu {
    display: none;
}

.header__menu-icon {
    width: 34px;
    height: 34px;
}

.header__menu-icon-fill {
    fill: var(--neutral-active);
}

.header__cart-and-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
}

.header__links {
    width: 100%;
    display: flex;
    /* overflow: hidden; */
    white-space: nowrap;

    position: relative;
}

.header__link {
    flex: none;
    padding: 0 28px;
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
    /* white-space: nowrap; */
    transition: all 0.2s linear;

    color: #101828;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

.header__link_active {
    /* color: var(--primary-active); */
    position: relative;
}

.header__link__active-triangle {
    position: absolute;
    bottom: -30px;
    left: 40%;
    opacity: 0;
    transition: all ease-in-out 0.5s;
}

.header__link_active .header__link__active-triangle {
    opacity: 1;
    transition: all ease-in-out 0.5s;
}

@media (max-width: 1000px) {
    .header__content {
        padding: 10px 16px;
    }
}

.header__search-box {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.search_mobile {
    display: none;
}

.search_pc {
    display: flex;
}

.header__search-icon-mobile {
    display: none;
    padding: 12px 12px;
}

@media (max-width: 1000px) {
    .header__catalog-with-links {
        column-gap: 6px;
    }
}

@media (max-width: 780px) {
    .search_mobile {
        display: flex;
    }

    .header__search-icon-mobile {
        display: flex;
    }

    .search_pc {
        display: none;
    }

    .header__content {
        grid-template-columns: 1fr minmax(max-content, 26px);
        padding: 10px 16px 10px 0;
        column-gap: 0;
    }

    .header__menu {
        display: flex;
        padding: 0 0 0 16px;
    }

    .header__input-icon {
        margin-top: 30px;
    }

    .header__catalog-with-links {
        display: none;
    }

    .header__catalog-btn {
        display: none;
    }

    .header__cart {
        width: 48px;
        height: 48px;
        box-sizing: border-box;
        padding: unset;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header__cart-text {
        display: none;
    }

    .header__content {
        /* padding: 16px; */
    }

    .header__logo {
        margin: auto;
    }

    .header__logo_pc {
        display: none;
    }

    .header__logo_mobile {
        display: block;
    }

    .header__logo-img {
        /* width: 100%; */
        max-width: 34px;
        max-height: 26px;
        object-fit: scale-down;
        object-position: left;
        margin-bottom: 2px;
        margin-left: 50px;
    }
}